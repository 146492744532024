import { useMemo } from "react";
import {
  User,
  MainNavigationItem,
  Button,
  MainNavigation,
  Link,
  SubNavigation,
  SubNavigationHeader,
  SubNavigationLink,
  SubNavigationContent,
  SubNavigationItem,
  BannerNavigation,
  BannerNavigationItem,
} from "@wfp/ui";
import authStore from "~/stores/auth";
import { observer } from "mobx-react";
import styles from "./styles.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import paths from "~/paths";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { UserPermission } from "~/user";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";

const Logo = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{ width: "max-content", cursor: "pointer", userSelect: "none" }}
      onClick={() => navigate(paths.construct.index())}
    >
      GODOCS
    </div>
  );
};

const TopSitesBar = () => {
  return (
    <BannerNavigation>
      <BannerNavigationItem>
        <Link href="http://communities.wfp.org" target="_blank">
          Communities
        </Link>
      </BannerNavigationItem>

      <BannerNavigationItem>
        <Link href="https://manuals.wfp.org/" target="_blank">
          Manuals
        </Link>
      </BannerNavigationItem>

      <BannerNavigationItem>
        <Link href="https://welearn.wfp.org" target="_blank">
          WeLearn
        </Link>
      </BannerNavigationItem>

      <BannerNavigationItem>
        <Link href="https://dashboard.wfp.org/" target="_blank">
          Dashboard
        </Link>
      </BannerNavigationItem>

      <BannerNavigationItem>
        <Link href="http://opweb.wfp.org" target="_blank">
          OPweb
        </Link>
      </BannerNavigationItem>

      <BannerNavigationItem>
        <Link href="https://selfservice.go.wfp.org/" target="_blank">
          Self-Service
        </Link>
      </BannerNavigationItem>

      <BannerNavigationItem>
        <Link href="https://humanitarianbooking.wfp.org/" target="_blank">
          UN Booking Hub
        </Link>
      </BannerNavigationItem>

      <BannerNavigationItem>
        <Link href="https://www.wfp.org/" target="_blank">
          WFP.org
        </Link>
      </BannerNavigationItem>
    </BannerNavigation>
  );
};

const Nav = observer(() => {
  const userInfo = authStore.userInfo;
  const navigate = useNavigate();
  const goToAdmin = () => window.location.assign(paths.construct.admin());
  const goToHelp = () => window.location.assign(paths.construct.help());

  const userSection = useMemo(() => {
    const username = userInfo !== null ? userInfo.username : "N/A";
    const email = userInfo !== null ? userInfo.email : "N/A";
    return (
      <MainNavigationItem
        className={cn(["wfp--main-navigation__user", styles.userNavWrapper])}
        subNavigation={
          <SubNavigation className={styles.userSubNavigation}>
            <SubNavigationHeader className="df">
              <div>Welcome!</div>
              <div className="fspace" />
              <SubNavigationItem>
                <Button small onClick={authStore.logOut}>
                  Logout
                </Button>
              </SubNavigationItem>
            </SubNavigationHeader>
            <SubNavigationContent>
              {userInfo && (userInfo.is_staff || userInfo.is_superuser) && (
                <SubNavigationItem className={styles.userNavItem}>
                  <Link href="#" onClick={goToAdmin} kind="navigation">
                    <FontAwesomeIcon icon={solid("wrench")} />
                    Admin
                  </Link>
                </SubNavigationItem>
              )}
              <SubNavigationItem className={styles.userNavItem}>
                <Link href="#" onClick={goToHelp}>
                  <FontAwesomeIcon icon={solid("question")} />
                  Help
                </Link>
              </SubNavigationItem>
            </SubNavigationContent>
          </SubNavigation>
        }
      >
        <User
          ellipsis
          title={username !== null ? username : "N/A"}
          image={userInfo?.avatar !== null ? userInfo?.avatar : undefined}
        >
          {email}
        </User>
      </MainNavigationItem>
    );
  }, [authStore.loggedIn, userInfo, userInfo?.avatar]);

  return (
    <header className={styles.header}>
      <TopSitesBar />
      <MainNavigation logo={<Logo />} pageWidth="full">
        <MainNavigationItem className={styles.userNavItem}>
          <NavLink className="wfp--link" to={paths.construct.index()}>
            Home
          </NavLink>
        </MainNavigationItem>
        <MainNavigationItem className={styles.userNavItem}>
          <NavLink className="wfp--link" to={paths.construct.documents()}>
            Documents
          </NavLink>
        </MainNavigationItem>
        <MainNavigationItem className={styles.userNavItem}>
          <NavLink className="wfp--link" to={paths.construct.personalArea()}>
            Personal area
          </NavLink>
        </MainNavigationItem>
        {authStore.hasPermission(UserPermission.UploadDoc) && (
          <MainNavigationItem className={styles.userNavItem}>
            <NavLink className="wfp--link" to={paths.construct.uploadDoc()}>
              <Button>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <FontAwesomeIcon icon={faFileUpload} style={{ marginRight: 3 }} /> Upload Document
              </Button>
            </NavLink>
          </MainNavigationItem>
        )}
        {userSection}
      </MainNavigation>
    </header>
  );
});

export default Nav;
