import React, { useMemo } from "react";
import styles from "./styles.module.scss";
import TabTable from "~/pages/PersonalArea/TabTable";
import { Column, usePagination, useSortBy, useTable } from "react-table";
import { DocItemType, Document } from "~/document";
import { NavLink, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { FetcherState } from "~/pages/PersonalArea/common";
import { PaginatedResult } from "~/util/PaginatedEndpoint";
import paths from "~/paths";
import { useSkipInitialRender } from "~/util/hooks";

type Props = {
  fetcher: FetcherState<Document>;
  items: PaginatedResult<Document>;
};

const HomeDocumentsTable = observer(({ fetcher, items }: Props) => {
  const navigate = useNavigate();
  const documentsTableData = useMemo(() => items?.results || [], [items]);

  const columns = useMemo(
    () =>
      [
        {
          Header: "Document title",
          accessor: `title`,
          Cell: ({ value, cell }) => {
            const { protocol } = cell.row.original;
            return (
              <NavLink
                title={value}
                to={paths.construct.docDetail(protocol.toString())}
                className="wfp--link two-lines-text-truncate"
              >
                {value}
              </NavLink>
            );
          },
          width: 350,
          minWidth: 150,
          maxWidth: 600,
        },
        {
          Header: "Document Symbol",
          accessor: "number",
        },
        {
          Header: "Meeting codes",
          accessor: "meetings",
          Cell: ({ value }) => {
            return value ? value.join(", ") : [];
          },
        },
        {
          Header: "Date",
          accessor: "date_added",
        },
        {
          Header: "Language",
          accessor: "language_display",
        },
      ] as Column<Document>[],
    []
  );

  const tableInstance = useTable(
    {
      columns: columns,
      data: documentsTableData,
      manualSortBy: true,
      initialState: {
        sortBy: [{ id: "date_added", desc: true }],
      },
    },
    useSortBy,
    usePagination
  );

  const onSort = () => fetcher.onSortingChange(tableInstance.state.sortBy);
  useSkipInitialRender(onSort, [tableInstance.state.sortBy]);

  return (
    <div className={styles.meetingsTableContainer}>
      <div className="hide-mobile">
        <TabTable
          fetcher={fetcher}
          tableInstance={tableInstance}
          emptyTitle="No documents"
          paginationProps={{
            pageSizesDisabled: true,
          }}
          emptyMessage="No documents uploaded"
        />
      </div>
    </div>
  );
});

export default HomeDocumentsTable;
