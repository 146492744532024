import { makeAutoObservable } from "mobx";
import PaginatedEndpoint from "~/util/PaginatedEndpoint";
import notifStore from "~/stores/notifications";
import * as api from "~/api";
import slug from "slug";

export type Tag = string;
export type TagId = string;
export interface TagInfo {
  id: TagId;
  label: string;
  last_modified_date: string;
  slug: string;
  uuid: string;
}

export class TagStore {
  f = new PaginatedEndpoint<TagInfo>("tags", notifStore);

  constructor() {
    makeAutoObservable(this);
  }

  async createNewTag(text: string) {
    const resp = await api.post("tags", { label: text, slug: slug(text) });
    this.f.saveItem(resp?.data);
    return resp;
  }
}

const tagStore = new TagStore();

export default tagStore;
