import React from "react";
import styles from "./styles.module.scss";
import { observer } from "mobx-react";
import { Button, Text } from "@wfp/ui";
import { useNavigate } from "react-router";
import paths from "~/paths";
import { useDataFetcher } from "~/pages/PersonalArea/common";
import documentStore from "~/stores/document";
import HomeDocumentsTable from "~/pages/HomePage/HomeDocumentsTable";

const Home = observer(() => {
  const navigate = useNavigate();
  const docsFetcher = useDataFetcher(true, documentStore.loadLatestDocuments, {
    type: "infinite",
    prevKey: "previous",
    defaultSortBy: "-date_added",
  });

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.sectionTitleRow}>
          <Text kind="h3" className="mt-0">
            Latest Documents
          </Text>
          <Button kind="secondary" onClick={() => navigate(paths.construct.uploadDoc())}>
            Add new
          </Button>
        </div>
        <HomeDocumentsTable fetcher={docsFetcher} items={docsFetcher.items} />
      </div>
    </div>
  );
});

export default Home;
