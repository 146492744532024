import { AssertionError } from "assert";
import capitalize from "lodash/capitalize";
import moment from "moment";
import { DATE_API_FORMAT, EXP_DATE_FORMAT, PUB_DATE_FORMAT } from "~/constants/index";

export const bytesToMB = (bytes: number) => {
  return Math.round((bytes / (1024 * 1024)) * 100) / 100;
};

// Async throttle
export function createThrottle(max: number) {
  let cur = 0;
  const queue: any[] = [];
  function throttle(fn: any) {
    return new Promise((resolve, reject) => {
      function handleFn() {
        if (cur < max) {
          throttle.current = ++cur;
          fn()
            .then((val: any) => {
              resolve(val);
              throttle.current = --cur;
              if (queue.length > 0) {
                queue.shift()();
              }
            })
            .catch((err: any) => {
              reject(err);
              throttle.current = --cur;
              if (queue.length > 0) {
                queue.shift()();
              }
            });
        } else {
          queue.push(handleFn);
        }
      }

      handleFn();
    });
  }

  // keep copies of the "state" for retrospection
  throttle.current = cur;
  throttle.queue = queue;

  return throttle;
}

//
// Flatten a tree structure.
//
// Imagine you have a Node, which has an array `.children` of type Node, and they have children and so-on.
// And you want to flatten them and get an array of all unique nodes contained within that tree.
//
type TreeNode<CK extends string> = {
  id: string | number;
  tid?: string | number;
} & {
  [Key in CK]?: TreeNode<CK>[];
};
type TreeFlattened<CK extends string, T extends TreeNode<CK>> = Map<T["id"], T>;
export function treeItemsById<CK extends string, T extends TreeNode<CK>, IK extends string>(
  treeChildren: T[],
  childrenKey: keyof T,
  includeParent: boolean = true,
  idKey: IK
): TreeFlattened<CK, T> {
  const map: TreeFlattened<CK, T> = new Map();
  const includeSubtreesArray = (subtrees: T[]) => {
    for (let subtree of subtrees) {
      const c = subtree[childrenKey];
      if (includeParent || !c) map.set(subtree[idKey as keyof T] as any, subtree);
      if (c && c.length !== 0) {
        // TODO: Remove this type coersion
        includeSubtreesArray(c as any);
      }
    }
  };
  includeSubtreesArray(treeChildren);
  return map;
}

// Used to describe the current status of a fetch() request
export const enum ReqStatus {
  Initial = 0,
  InProcess = 1,
  Success = 2,
  Failed = 3,
}

export const declString = (s: string, multiple: boolean) => {
  if (multiple) return `${s}s`;
  return s;
};

export function assert(condition: boolean, msg?: string): asserts condition {
  if (!condition) {
    throw new AssertionError({ message: msg ?? "Assertion failed" });
  }
}

export const dateToAPIString = (date: Date | string): string => {
  return moment(date).format(DATE_API_FORMAT);
};

export function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

// Create a new map B->A from a map that maps A->B
export function reverseMap<K extends symbol | string, V>(map: Record<K, V | V[] | null>): Map<V, K> {
  const res = new Map();
  for (let [key, field] of Object.entries(map)) {
    if (field instanceof Array) {
      for (const f of field) {
        res.set(f, key);
      }
    } else if (field === null) {
      continue;
    } else {
      res.set(field, key);
    }
  }
  return res;
}

// convert camel case json field into a readable capitalized version
export const fieldReadable = (field: string | number | symbol): string => {
  return capitalize(String(field).replaceAll("_", " "));
};

export const formatDateReadable = (
  date: Date | string,
  formatDate: typeof DATE_API_FORMAT | typeof EXP_DATE_FORMAT = PUB_DATE_FORMAT
) => {
  return moment(date).format(formatDate);
};

export const formatDateReadableRange = (date: (Date | string)[]): string => {
  const a = formatDateReadable(date[0]) ?? "N/A";
  const b = formatDateReadable(date[1]) ?? "N/A";
  return `${a} -> ${b}`;
};

export const formatBytes = (bytes_number: number, decimals = 2) => {
  if (bytes_number === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes_number) / Math.log(k));

  return parseFloat((bytes_number / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getFullDocTypes = (document: any): string => {
  let docTypeContent = [document.type_display];
  if (document.secondary_type_display) {
    docTypeContent.push(document.secondary_type_display);
  }
  return docTypeContent.join(", ");
};

export const withLoading = (promise: () => Promise<any>, loadingDispatch: (loading: boolean) => void) => {
  loadingDispatch(true);
  promise().finally(() => loadingDispatch(false));
};
