import { bytesToMB } from "~/util";
import { API_ROOT, EBMS_API_ROOT } from "~/api";
import tagStore, { TagId } from "~/stores/tags";
import topicStore, { TopicNode } from "~/stores/topics";
import languageStore, { LanguageInfo } from "~/stores/language";
import countryStore, { CountryId, CountryInfo } from "~/stores/country";
import docTypeStore from "~/stores/documentTypes";
import docSeriesStore from "~/stores/docSeries";
import ownerStore from "~/stores/owners";
import officeStore from "~/stores/offices";
import divisionStore from "~/stores/divisions";
import moment from "moment";
import { PUB_DATE_FORMAT, EXP_DATE_FORMAT } from "~/constants";
import { UserId } from "~/user";
import { DocLangVersion } from "~/stores/langVersionStore";

export const DOC_PUB_DATE_FORMAT = PUB_DATE_FORMAT;

export type ID = number;
export type DocumentId = ID;
export type DocTypeId = string | number;

export type DateRange = [Date | null, Date | null];

export type DocSeriesId = number;
export interface DocSeriesInfo {
  id: DocSeriesId;
  label: string;
}

export type DocInstanceId = number;

export enum DocInstance {
  Formal = 0,
  Informal = 1,
  CFA = 2,
  IGC = 3,
}
export const docInstanceLabel = {
  [DocInstance.Formal]: "Formal",
  [DocInstance.Informal]: "Informal",
  [DocInstance.CFA]: "CFA",
  [DocInstance.IGC]: "IGC",
};

export interface Metadata {
  docustore: {
    SCORE: string;
    xYear: string;
    TITLE: string;
  };
  application_owner: string;
  ebmeetingdocs?: {
    meeting_codes?: { [key: string]: string[] };
    document_instance?: { label: string; value: DocInstanceId };
    meeting_types?: string[];
    comment?: string;
    decision?: string;
    disclaimer?: string;
    summary?: string;
  };
}

export interface Custom {
  meeting_codes?: { [key: string]: string[] };
  document_instance?: { label: string; value: DocInstanceId } | Record<string, never>;
  meeting_types?: string[];
  comment?: string;
  decision?: string;
  disclaimer?: string;
  summary?: string;
  is_title_visible_to_public?: 1 | 0;
}

export enum Access {
  Public = 0,
  LoginRequired = 10,
  Restricted = 20,
}

const accessToTextMap: Record<Access, string> = {
  [Access.Public]: "Public",
  [Access.LoginRequired]: "Login required",
  [Access.Restricted]: "Restricted access",
};
export const accessToText = (access: Access) => accessToTextMap[access];

export type TopicId = number | string;
export type DocumentProto = string;

type Mimetype = string;

export type FileExtension = string;

export interface FileInfo {
  size: string;
  ext: FileExtension;
  name: string;
  mimetype: Mimetype;
}

export type LangId = string;

export type Owner = string;
export type OwnerId = string;
export interface OwnerInfo {
  id: OwnerId;
  username: string;
  first_name?: string;
  last_name?: string;
}

export interface DocumentSearch {
  id: number;
  dbid: number;
  uuid: string;
  title: string;
  access: number;
  abstract: string;
  owner: number;
  symbol: string;
  types: {
    id: number;
    label: string;
  }[];
  archived: boolean;
  topics: TopicNode[];
  countries: CountryInfo[];
  release_date: string | null;
  expiration_date: string | null;
  publication_date: string | null;
  language: string;
  languages: LanguageInfo[];
  file_info: FileInfo;
  protocol: string;
  metadata: Metadata;
  date_added: string;
}

export interface DocItemType {
  id: number;
  api_url: string;
  uuid: string;
  creation_date: string;
  title: string;
  protocol: DocumentProto;
  symbol: string;
  meetings: string[];
  language: string;
  language_display: string;
  featured: boolean;
  dss_data_preview: {
    type: number;
    owner: number;
    number: string;
    topics: number[];
    abstract: string;
    countries: number[];
    file_info: FileInfo;
    type_display: string;
    owner_display: string;
    secondary_type: 25;
    topics_display: string;
    publication_date: string;
    expiration_date: string;
    countries_display: string;
    secondary_type_display: string;
  };
  agendaitems: any[];
}

export interface Document {
  id: ID;
  uuid: string;
  file: string;
  protocol: DocumentProto;
  meeting_codes?: string[];
  instance?: DocInstanceId;
  // base64 representation
  file_preview: string;

  versions: string[];

  trans_group_id: string;

  uploader: number;

  expiration_date: string;
  release_date: string;
  publication_date: string;
  date_added: string;
  last_modified_date: string;

  owner: OwnerId;
  owner_display: string;

  object_permission: boolean;

  uploader_display: string;
  type_display: string;
  secondary_type_display: string;
  third_type_display: string;
  date_display: string;
  status_display: string;
  office_display: string;
  division_display: string;

  language_display: string;
  topics_display: string;
  countries_display: string;

  pdf_option: number; // enum

  current_user_is_manager: string;
  tags: TagId[];
  tags_display: string[];
  relations: any[];
  related: DocRelation[];

  trans_group: DocLangVersion[];
  metadata?: Metadata;

  custom: Custom;
  custom_application: string;
  custom_token: string;

  last_modified_user: string;

  docustore_docname: string;

  number: string;
  index_status: number;
  title: string;

  comment: string;

  author: string;
  language: LangId;

  access: Access;

  type: DocTypeId;
  secondary_type: DocTypeId;
  types: DocTypeId[];

  topics: TopicId[];
  countries: CountryId[];

  file_info: FileInfo;

  translations: [string, string][];

  access_display: string;

  abstract: string;

  dbid: number;

  serie: DocSeriesId;
  serie_display: string;

  projects: ProjectId[];
  office: OfficeId;
  division: DivisionId;
}

export type Division = string;
export type DivisionId = string;
export interface DivisionInfo {
  id: DivisionId;
  name: string;
  code?: string;
}

export type Office = string;
export type OfficeId = string;
export interface OfficeInfo {
  id: OfficeId;
  name: string;
  timezone?: string;
}

export type ProjectId = string;
export interface ProjectInfo {
  id: ProjectId;
  title: string;
  last_modified_date: string;
  code: string;
  short_title: string;
  uuid: string;
}

export type TranslationInfo = Pick<Document, "uuid" | "language" | "title" | "abstract" | "protocol" | "file_info">;

const documentPDFOptionEnum = {
  3: "PDF Preferred",
};

export const docPDFOptionStr = (document: Document) => {
  return (documentPDFOptionEnum as any)[document.pdf_option];
};

export const docFileType = (document: Document) => {
  // TODO: Maybe use an enum instead and map the mimetype to it?
  if (!document.file_info.ext) return null;
  return document.file_info.ext.substr(1);
};

export const docFileSize = (document: Document | DocumentSearch) => {
  if (!document.file_info.size) return null;
  return `${bytesToMB(parseInt(document.file_info.size))} MB`;
};

export const documentDownloadLink = (document: Document | DocumentSearch) => {
  //https://api.dev.godocs.wfp.org/api/documents/WFP-0000012008/download/
  return `${API_ROOT}/documents/${document.protocol}/download/`;
};

export type DocDateFields = "expiration_date" | "publication_date" | "release_date";
export type DocPayload = Partial<Document>;

export type EditableDocPayload = Partial<DocPayload>;

export type APIDocPayload = Omit<DocPayload, DocDateFields> & {
  [P in DocDateFields]: string | undefined;
};

export type DocPayloadForEdit = Partial<APIDocPayload>;

export const requiredFieldsForUpload = [
  "publication_date",
  "expiration_date",
  "release_date",
  "title",
  "type",
  "topics",
] as const;

type DocUploadRequiredFields = (typeof requiredFieldsForUpload)[number];

export type DocPayloadForUpload = Required<Pick<APIDocPayload, DocUploadRequiredFields>>;

export const docItemToDocument = (doc: DocItemType): Document => {
  const countries = doc.dss_data_preview.countries;
  const { dss_data_preview, ...docItem } = doc;
  return {
    ...docItem,
    ...doc.dss_data_preview,
    access: 0,
    access_display: "",
    author: "",
    comment: "",
    countries: [],
    current_user_is_manager: "",
    custom: undefined,
    date_added: doc.creation_date,
    date_display: "",
    dbid: doc.id,
    division: "",
    division_display: "",
    docustore_docname: "",
    file: doc.dss_data_preview.file_info?.name,
    file_preview: doc.dss_data_preview.file_info?.ext,
    index_status: 0,
    last_modified_date: "",
    last_modified_user: "",
    number: doc.symbol,
    object_permission: false,
    office: "",
    office_display: "",
    owner: "",
    owner_display: "",
    pdf_option: 0,
    projects: [],
    related: [],
    relations: [],
    release_date: doc.dss_data_preview.publication_date,
    secondary_type: undefined,
    secondary_type_display: "",
    serie: 0,
    serie_display: "",
    status_display: "",
    tags: [],
    tags_display: [],
    third_type_display: "",
    title: doc.title,
    trans_group: [],
    trans_group_id: "",
    translations: [],
    types: [doc.dss_data_preview.type, doc.dss_data_preview.secondary_type],
    uploader: 0,
    uploader_display: "",
    uuid: doc.uuid,
    versions: [],
    custom_application: "ebmeetingdocs",
    custom_token: "",
  };
};

export const toJSON = (doc: DocPayload) => {
  return {
    title: doc.title,
    languages: doc.language ? languageStore.loadedLanguages[doc.language]?.text ?? null : null,
    tags: doc.tags_display
      ? doc.tags_display.join(", ")
      : doc.tags
      ? doc.tags.map((tagId) => tagStore.f.items[tagId]?.label ?? null)
      : null,
    author: doc.author,
    topics:
      doc.topics_display ??
      (doc.topics
        ? (Array.from(doc.topics)
            .map((id) => {
              return topicStore.topicsById.get(id)?.label || null;
            })
            .filter((a) => a !== null) as string[])
        : null),
    "Document types":
      doc.type_display ??
      (doc.types
        ? (Array.from(doc.types)
            .map((id) => {
              return docTypeStore.docTypesById.get(id!)?.label || null;
            })
            .filter((a) => a !== null) as string[])
        : null),
    "Geographical coverage":
      doc.countries_display ??
      (doc.countries
        ? Array.from(doc.countries).map((id) => {
            const sid = String(id);
            return countryStore.f.items[sid]?.name;
          })
        : null),
    Owners: doc.owner_display ?? (doc.owner !== undefined ? ownerStore.f.items[doc.owner]?.username ?? null : null),
    "Document symbol": doc.number,
    "Document ID": doc.protocol,
    "Project number": doc.projects,
    "Meeting codes": doc.meeting_codes ? doc.meeting_codes.join(", ") : "",
    Access: doc.access ? accessToText(doc.access) : null,
    // Instances: doc.instances.map((id) => {
    //   return docInstanceLabel[id];
    // }),
    Series: doc.serie ? docSeriesStore.loaded[doc.serie]?.label ?? null : null,
    Offices: doc.office ? officeStore.f.items[doc.office]?.name ?? null : null,
    Divisions: doc.division ? divisionStore.f.items[doc.division]?.name ?? null : null,
    "Publication date": doc.publication_date ? moment(doc.publication_date).format(PUB_DATE_FORMAT) : null,
    "Expiration date": doc.expiration_date ? moment(doc.expiration_date).format(EXP_DATE_FORMAT) : "",
  };
};

export type DocumentRelationTypeId = number;

export interface DocumentRelationType {
  id: DocumentRelationTypeId;
  last_modified_date: Date;
  version: string;
  uuid: string;
  left_to_right: string;
  right_to_left: string;
  deprecated: boolean;
  last_modified_user: UserId;
}

type DocRelationId = number;

export type DocRelation = {
  id?: DocRelationId;
  is_owner?: boolean;
  other: Pick<Document, "access" | "title" | "id" | "uuid" | "protocol" | "language" | "file_info">;
  type: DocumentRelationTypeId; // type of relation
  verbose?: string;
};

export const docRelationTypeString = (docRelation: DocRelation) => {
  return docRelation.verbose?.split(" ")[0] ?? "N/A";
};

export interface DocVersion {
  uuid: string;
  filesize: number;
  timestamp: Date;
  file: string;
  id: number;
  last_modified_date: Date;
  is_active: boolean;
  conversion_status: number;
}

const singularFields: Set<keyof EditableDocPayload> = new Set([
  "owner",
  "serie",
  "division",
  "office",
  "language",
  "access",
]);

export const isDocFieldSingle = (fieldName: keyof EditableDocPayload): boolean => {
  return singularFields.has(fieldName);
};
