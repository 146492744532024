import React from "react";
import tagStore, { Tag, TagId } from "~/stores/tags";
import { useRef, useCallback, useState } from "react";
import { observer } from "mobx-react";
import AsyncPaginatedSelect from "~/components/AsyncPaginatedSelect";

interface Props {
  value: TagId[];
  onChange: (newValue: TagId[]) => void;
  isCreatable?: boolean;
}

const TagSelect = observer((props: Props) => {
  const fetcher = tagStore.f;
  const idToOpt = (id: string | TagId) => {
    const item = fetcher.itemsList.find((tag) => tag.label === id || tag.id === id);
    if (!item) return null;
    return {
      label: item.label,
      value: item.label,
    };
  };
  const onCreate = async (option: string) => {
    const res = await tagStore.createNewTag(option);
    return res?.data;
  };
  return (
    <AsyncPaginatedSelect
      isCreatable={props.isCreatable ?? false}
      fetcher={tagStore.f}
      itemIdToOption={idToOpt}
      value={props.value}
      onCreate={onCreate}
      onChange={props.onChange}
      selectProps={{
        isMulti: true,
      }}
    />
  );
});

export default TagSelect;
