import { FormGroup as WFPFormGroup, Icon } from "@wfp/ui";
import { ComponentProps } from "react";
import { iconWarningGlyph } from "@wfp/icons";
import styles from "./styles.module.scss";

// Form group that shows errors below
const FormGroup = (props: ComponentProps<typeof WFPFormGroup>) => {
  const { invalid, invalidText } = props;
  const errorIcon = <Icon icon={iconWarningGlyph} fill="#c5192d" />;

  const error = invalid ? (
    <div className={`wfp--form-requirement`} style={{ display: "block" }}>
      {errorIcon}{" "}
      <span>
        {typeof invalid === "object" && invalid.message
          ? invalid.message
          : typeof invalid === "string"
          ? invalid
          : invalidText
          ? invalidText
          : "required"}
      </span>
    </div>
  ) : null;

  return (
    <div className={styles.customFormGroup}>
      <WFPFormGroup {...props}>
        {props.children}
        {error}
      </WFPFormGroup>
    </div>
  );
};

export default FormGroup;
