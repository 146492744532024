import React, { useEffect, useMemo } from "react";
import { Loading, Text, Button } from "@wfp/ui";
import styles from "./styles.module.scss";
import { Link, useNavigate } from "react-router-dom";
import paths from "~/paths";
import cn from "classnames";
import { iconArrowRight } from "@wfp/icons";
import LanguageDisplay from "~/components/LanguageDisplay";
import DocumentPreview from "../DocumentPreview";
import ShareDocumentButton from "~/components/DocumentShareButton";
import DocumentDownloadButton from "~/components/DocumentDownloadButton";
import { toJSON, TranslationInfo, Document } from "~/document";
import DocBookmarkButton from "../DocBookmarkButton";
import languageStore from "~/stores/language";
import IconButton from "../IconButton";
import { iconClose } from "@wfp/icons";
import bookmarkStore from "~/stores/bookmarks";
import { observer } from "mobx-react";
import documentStore from "~/stores/document";
import { getFullDocTypes } from "~/util";
import docStore from "~/stores/document";
import { splitMeetingCodes } from "~/pages/UploadPage";

interface DocumentInfoProps {
  document: Document;
  translations: TranslationInfo[];
}

const DocumentInfo = (props: DocumentInfoProps) => {
  const { translations, document } = props;
  const items = toJSON(document as any);
  const itemsList = [
    { text: "Publication Date", content: items["Publication date"] },
    {
      text: "Available languages",
      content: <LanguageDisplay document={document} translations={translations} />,
    },
    { text: "Access", content: document.access_display },
    { text: "Topics", content: items["topics"] },
    { text: "Document types", content: getFullDocTypes(document) },
    { text: "Geographical coverage", content: items["Geographical coverage"] },
    { text: "Document symbol", content: items["Document symbol"] },
    { text: "Document ID", content: items["Document ID"] },
    {
      text: "Meeting codes",
      content: document.metadata?.ebmeetingdocs?.meeting_codes
        ? splitMeetingCodes(document.metadata.ebmeetingdocs.meeting_codes).join(", ")
        : null,
    },
    { text: "Tags", content: items["tags"] },
  ];
  return (
    <div className={styles.documentInfo}>
      {itemsList
        .filter(
          (item) =>
            !!item.content &&
            (!(item.content instanceof Array) || item.content.length !== 0) &&
            (!(item.content instanceof Set) || item.content.size !== 0)
        )
        .map((item) => (
          <div className={styles.documentInfoItem} key={item.text}>
            <strong className={styles.documentInfoItemLabel}>{item.text}</strong>
            <Text kind="p" className="mt-8">
              {item.content}
            </Text>
          </div>
        ))}
    </div>
  );
};

interface Props {
  document: Document | null;
  loadingDocument: boolean;
  translations: TranslationInfo[];
  onClose: () => void;
}

const DocumentView = observer((props: Props) => {
  const { translations } = props;
  const doc = props.document;
  const navigate = useNavigate();
  const loadingDocument = documentStore.loadingDocumentViewInfo;

  useEffect(() => {
    if (doc === null) {
      return;
    }
    languageStore.loadLanguages();
  }, [doc]);

  const renderedDocument = useMemo(() => {
    if (loadingDocument) {
      return (
        <div className={styles.container}>
          <div className="df h-100 w-100 fc f-100">
            <Loading className="loader loader_sm" withOverlay={false}>
              Loading...
            </Loading>
          </div>
        </div>
      );
    }

    const canDownload = docStore.canDownload(docStore.currentDocument);

    if (doc === null) return null;
    return (
      <div className={styles.container}>
        <div className="df fdr h-100 f-100">
          <div className={styles.leftCol}>
            <div className="mb-16 df fdr fvc">
              <div className={cn(["tal", styles.documentViewTitle])}>
                <div className="df fdr">
                  <Text kind="h3" className="no-margin">
                    {doc.title}
                  </Text>
                </div>
                {doc.abstract && (
                  <Text kind="p" className="text-02">
                    {doc.abstract}
                  </Text>
                )}
              </div>
            </div>
            <div className="mb-32">
              <ShareDocumentButton doc={doc} className={styles.docViewAction} />
              {canDownload && (
                <>
                  <DocumentDownloadButton doc={doc} className={styles.docViewAction} />
                  <DocBookmarkButton doc={doc} className={styles.docViewAction} />
                </>
              )}
            </div>
            <div>
              <DocumentInfo translations={translations} document={doc} />
            </div>
          </div>
          <div className={styles.rightCol}>
            <div className="df fdrr mb-24">
              <IconButton description="Close the view" icon={iconClose} onClick={props.onClose}></IconButton>
            </div>
            <div className="f-100">
              <DocumentPreview doc={doc} className={styles.documentPreview} />
            </div>
            <div className="fspace" />
            <div className="df fdrr mt-16">
              <Link to={paths.construct.docDetail(doc.protocol)} target="_blank" rel="noopener noreferrer">
                <Button kind="primary" icon={iconArrowRight}>
                  Document details
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }, [doc, loadingDocument]);

  return <div className={styles.documentView}>{renderedDocument}</div>;
});

export default DocumentView;
