import { runInAction, makeAutoObservable, action, toJS } from "mobx";
import * as api from "~/api";
import { NotificationStore } from "~/stores/notifications";
import { RecordKey, DataWithId } from "~/types";
import { END_PAGINATION_CURSOR } from "~/constants";
import { ReqStatus } from "~/api";
import identity from "lodash/identity";

export interface PaginatedResult<T> {
  count: number;
  current_page: number;
  next: string | null;
  previous: string | null;
  results: T[];
  total_pages: number;
}

interface Options<T> {
  byId?: keyof T;
  loadOnlyOnce?: boolean;
  itemTransformer?: (item: any) => T;
  searchField?: string;
}

class PaginatedEndpoint<T extends DataWithId> {
  items: Record<RecordKey, T> = {};
  itemsList: T[] = [];
  cursor: string | null | undefined = undefined;
  endpoint: string;
  notifStore: NotificationStore;
  byKey: keyof T;
  lastSearchedQuery: string | null = null;
  reqStatus: ReqStatus = ReqStatus.Initial;
  loadOnlyOnce: boolean = false;
  itemTransformer: (item: any) => T;
  searchField: string;
  deletionStatus = ReqStatus.Initial;
  idsBeingDeleted: Set<T["id"]>;

  constructor(endpoint: string, notifStore: NotificationStore, options: Options<T> = {}) {
    this.loadOnlyOnce = options.loadOnlyOnce ?? false;
    this.endpoint = endpoint;
    this.notifStore = notifStore;
    this.byKey = options.byId ?? "id";
    this.itemTransformer = options.itemTransformer ?? identity;
    this.searchField = options.searchField ?? "search";
    this.idsBeingDeleted = new Set();
    makeAutoObservable(this);
  }

  get hasMore(): boolean {
    return this.cursor !== END_PAGINATION_CURSOR;
  }

  get loading(): boolean {
    return this.reqStatus === ReqStatus.InProcess;
  }

  delete = async (id: T["id"]) => {
    try {
      runInAction(() => {
        this.deletionStatus = ReqStatus.InProcess;
        this.idsBeingDeleted.add(id);
      });
      await api.del(`${this.endpoint}/${id}/`);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      delete this.items[id];
      this.deletionStatus = ReqStatus.Success;
      this.idsBeingDeleted.delete(id);
    } catch (err) {
      this.notifStore.error("Failed to delete object");
      this.deletionStatus = ReqStatus.Failed;
      this.idsBeingDeleted.delete(id);
    }
  };

  isDeleting = (id: T["id"]): boolean => {
    return this.idsBeingDeleted.has(id);
  };

  loadItem = async (id: T["id"]): Promise<T | null> => {
    const resp = await api.get(`${this.endpoint}/${id}`);
    if (resp === null) return null;
    const item = this.itemTransformer(resp.data);
    this.saveItem(item);
    return item;
  };

  loadBare = async (
    search: string,
    cursor: string | null = null,
    additionalSearchOptions?: { [key: string]: string } | string
  ): Promise<PaginatedResult<T> | null> => {
    let resp = null;
    let params = {};
    if (search) {
      params[this.searchField] = search;
    }
    if (additionalSearchOptions && typeof additionalSearchOptions !== "string") {
      params = { ...params, ...additionalSearchOptions };
    }
    if (cursor === null) {
      resp = await api.get(this.endpoint, params);
    } else {
      resp = await api.getBare(cursor);
    }
    if (resp === null) return null;
    this.saveItems(resp.data.results);
    if (resp.data.results) {
      resp.data.results = resp.data.results.map(this.itemTransformer);
    }
    return resp.data;
  };

  saveItem = (item: T) => {
    this.items[item[this.byKey] as any] = item;
    this.itemsList = this.itemsList.map((oldItem) => (oldItem[this.byKey] !== item[this.byKey] ? oldItem : item));
  };

  saveItems = (items: T[]) => {
    this.items = {
      ...this.items,
      ...items.reduce((acc, item) => {
        const itemTransformed = this.itemTransformer(item);
        return { ...acc, [item[this.byKey] as any]: itemTransformed };
      }, {}),
    };
    const itemsIds = this.itemsList.map((item) => item[this.byKey]);
    this.itemsList = [
      ...this.itemsList,
      ...items.filter((item) => !itemsIds.includes(item[this.byKey])).map((item) => this.itemTransformer(item)),
    ];
  };

  // Load only the specified ids
  loadIds = async (ids: T["id"][]): Promise<T[]> => {
    runInAction(() => {
      this.reqStatus = ReqStatus.InProcess;
    });
    try {
      const params: any = {};
      if (ids.length === 1) {
        params["id"] = ids[0];
      } else if (ids.length > 1) {
        params["id__inlist"] = ids;
      }
      const resp = await api.get(this.endpoint, params);
      if (resp === null) return [];
      const data: PaginatedResult<T> = resp.data;
      const items: T[] = data.results;
      this.saveItems(items);
      this.reqStatus = ReqStatus.Success;
      return items;
    } catch (err: any) {
      runInAction(() => {
        this.reqStatus = ReqStatus.Failed;
      });
      throw err;
    }
  };

  load = async (
    search: string,
    cursor: string | null = null,
    additionalSearchOptions?: { [key: string]: string } | string
  ): Promise<[T[], boolean]> => {
    if (this.loadOnlyOnce && this.reqStatus !== ReqStatus.Initial) return [[], false];
    if (this.lastSearchedQuery !== null && search !== this.lastSearchedQuery) {
      this.cursor = null;
    }
    runInAction(() => {
      this.reqStatus = ReqStatus.InProcess;
    });
    try {
      const resp = await this.loadBare(search, cursor, additionalSearchOptions);
      if (resp === null) return [[], true];
      const data: PaginatedResult<T> = resp;
      const tags: T[] = data.results;
      this.saveItems(tags);
      runInAction(() => {
        this.reqStatus = ReqStatus.Success;
        if (data.next === null) {
          this.cursor = END_PAGINATION_CURSOR;
        } else {
          this.cursor = data.next;
        }
      });
      const hasMore = this.hasMore;
      this.lastSearchedQuery = search;
      return [tags, hasMore];
    } catch (err: any) {
      runInAction(() => {
        this.reqStatus = ReqStatus.Failed;
      });
      throw err;
    }
  };
}

export default PaginatedEndpoint;
