import { useEffect, useRef, useState } from "react";
import { ReqStatus } from "~/api";
import { PaginatedResult } from "~/util/PaginatedEndpoint";

export const TITLE_MAX_CHARS = 80;

export interface FetcherState<T> {
  loading: boolean;
  items: PaginatedResult<T>;
  itemsCombined: T[];
  page: number;
  totalPages: number;
  totalItems: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (page: number) => void;
  fetchNextPage: () => void;
  reload: () => Promise<void>;
  nextPageExists: boolean;
  onSortingChange: (sort: { id: string; desc?: boolean }[]) => void;
}

export function useDataFetcher<T extends { id: number | string }>(
  selected: boolean,
  fetch: (search: string, cursor: string | null, sortBy?: string) => Promise<PaginatedResult<T> | null>,
  options?: {
    clientOnlyPagination?: boolean;
    nextKey?: string;
    prevKey?: string;
    currentPageKey?: string;
    pagesCountKey?: string;
    itemsCountKey?: string;
    pageSizeKey?: string;
    initialFetchDependencyList?: any[];
    type?: "pagination" | "infinite";
    defaultSortBy?: string;
  }
): FetcherState<T> {
  const clientOnlyPagination = options?.clientOnlyPagination ?? false;
  const {
    nextKey = "next",
    prevKey = "prev",
    currentPageKey = "current_page",
    pagesCountKey = "total_pages",
    itemsCountKey = "count",
    initialFetchDependencyList = [selected],
    type = "pagination",
    defaultSortBy = null,
  } = options || {};
  const [itemsCombined, setItemsCombined] = useState<T[]>([]);
  const [items, setItems] = useState<PaginatedResult<T>>({ results: [] } as any);
  const [reqStatus, setReqStatus] = useState<ReqStatus>(ReqStatus.Initial);
  const [sortBy, setSortBy] = useState<string | null>(defaultSortBy);
  const prevCursor = useRef<string | null>(null);
  const fetchWithCursor = async (cursor: string | null) => {
    prevCursor.current = cursor;
    setReqStatus(ReqStatus.InProcess);
    const d = await fetch("", cursor, sortBy);
    if (!d) return;
    if (type === "infinite") {
      const itemsIds = itemsCombined.map((item) => item.id);
      setItemsCombined([...itemsCombined, ...d.results.filter((item) => !itemsIds.includes(item.id))]);
    }
    setItems(d);
    setReqStatus(ReqStatus.Success);
  };
  useEffect(() => {
    if (selected) {
      setReqStatus(ReqStatus.InProcess);
      fetchWithCursor(null)
        .then(() => setReqStatus(ReqStatus.Success))
        .catch(() => setReqStatus(ReqStatus.Failed));
    }
  }, initialFetchDependencyList);
  const loading = reqStatus === ReqStatus.InProcess || reqStatus === ReqStatus.Initial;
  const page = items?.[currentPageKey] ?? 1;
  const totalPages = items?.[pagesCountKey] ?? 1;
  const totalItems = items?.[itemsCountKey] ?? 0;

  const fetchNextPage = () => {
    fetchWithCursor(items[nextKey]);
  };

  const onPageChange = async (newPage: number) => {
    newPage = newPage + 1;
    if (clientOnlyPagination) {
      setItems({ ...items, current_page: newPage });
    } else {
      if (newPage > page) {
        await fetchWithCursor(items[nextKey]);
      } else if (newPage < page) {
        await fetchWithCursor(items[prevKey]);
      }
    }
  };
  const onPageSizeChange = (pageSize: number) => {};
  const reload = async () => {
    await fetchWithCursor(prevCursor.current);
  };

  const onSortingChange = (sort: { id: string; desc?: boolean }[]) => {
    if (sort[0]) {
      let { id, desc } = sort[0];
      if (id.includes("title_")) {
        // sortBy parameter only accepts 'title' value
        id = "title";
      }
      setSortBy(desc ? `-${id}` : id);
    } else {
      setSortBy("");
    }
  };

  useEffect(() => {
    if (sortBy !== null) {
      let cursor = prevCursor.current;
      if (cursor) {
        const url = new URL(cursor);
        url.searchParams.set("ordering", sortBy);
        cursor = url.toString();
      }
      fetchWithCursor(cursor);
    }
  }, [sortBy]);

  return {
    loading,
    items,
    itemsCombined,
    page,
    totalPages,
    totalItems,
    onPageChange,
    onPageSizeChange,
    fetchNextPage,
    reload,
    nextPageExists: !!items[nextKey],
    onSortingChange,
  };
}
