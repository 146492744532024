import { InputGroup, RadioButton } from "@wfp/ui";

interface Props {
  value: Date | null | undefined;
  onChange: (a: Date | null) => void;
  disabled?: boolean;
  startDate?: Date;
}

const ExpirationDateSelect = (props: Props) => {
  const { value, onChange } = props;
  const disabled = props.disabled ?? false;
  const todayPrecise = props.startDate ? props.startDate : new Date();
  const today = new Date(todayPrecise.getFullYear(), todayPrecise.getMonth(), todayPrecise.getDate());
  const nextYear = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
  const sixYears = new Date(today.getFullYear() + 6, today.getMonth(), today.getDate());
  const tenYears = new Date(today.getFullYear() + 10, today.getMonth(), today.getDate());
  const twentyYears = new Date(today.getFullYear() + 20, today.getMonth(), today.getDate());
  const expDateOptions = [
    { value: nextYear.toString(), label: "1 year" },
    { value: sixYears.toString(), label: "6 years" },
    { value: tenYears.toString(), label: "10 years" },
    { value: twentyYears.toString(), label: "20 years" },
    { value: null, label: "Never" },
  ];

  return (
    <InputGroup name="input-group" vertical>
      {expDateOptions.map((opt) => {
        const vs = String(opt.value);
        const checked = String(value) === vs;
        return (
          <RadioButton
            key={vs}
            id={vs}
            labelText={opt.label}
            name={vs}
            onChange={(a) => onChange(a.target.value === String(null) ? null : new Date(a.target.value))}
            checked={checked}
            value={vs}
            disabled={disabled}
          />
        );
      })}
    </InputGroup>
  );
};

export default ExpirationDateSelect;
