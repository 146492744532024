import ownerStore, { OwnerId } from "~/stores/owners";
import { observer } from "mobx-react";
import AsyncPaginatedSelect from "~/components/AsyncPaginatedSelect";

interface Props {
  value: OwnerId[];
  onChange: (newValue: OwnerId[]) => void;
  selectProps?: any;
}

const OwnerSelect = observer((props: Props) => {
  const fetcher = ownerStore.f;
  const idToOpt = (id: OwnerId) => {
    const item = fetcher.items[id];
    if (!item || !item.username) return null as any;
    return {
      label:
        item.first_name && item.last_name ? `${item.first_name} ${item.last_name} (${item.username})` : item.username,
      value: item.id,
    };
  };
  return (
    <AsyncPaginatedSelect
      fetcher={ownerStore.f}
      itemIdToOption={idToOpt}
      value={props.value}
      onChange={props.onChange}
      selectProps={{
        isMulti: true,
        ...(props.selectProps || {}),
      }}
    />
  );
});

export default OwnerSelect;
