import React, { useMemo } from "react";
import { List, ListItem, Text } from "@wfp/ui";
import styles from "./styles.module.scss";
import capitalize from "lodash/capitalize";
import { DocPayload, toJSON } from "~/document";

const DocumentSummary = (props: { doc: DocPayload }) => {
  const summary = useMemo(
    () => Object.fromEntries(Object.entries(toJSON(props.doc)).filter(([_, val]) => val)),
    [props.doc]
  );
  return (
    <List className={styles.docSummaryList} kind="details">
      {Object.entries(summary).map(([itemName, itemValue]) => {
        if (itemValue instanceof Array) {
          return (
            <ListItem
              key={itemName}
              className={styles.listItem}
              title={<Text className={styles.listItemTitle}>{capitalize(itemName)}</Text>}
            >
              <List className={styles.docSummarySubList} kind="details">
                {itemValue.length === 0 ? (
                  <Text className="fs-12">None selected</Text>
                ) : (
                  itemValue.map((a, idx) => {
                    const rendered = a;
                    return (
                      <Text key={idx} className={styles.listItemTitle}>
                        {rendered}
                      </Text>
                    );
                  })
                )}
              </List>
            </ListItem>
          );
        } else {
          const rendered = itemValue;
          return (
            <ListItem
              key={itemName}
              className={styles.listItem}
              title={<Text className={styles.listItemTitle}>{capitalize(itemName)}</Text>}
            >
              <Text className="fs-12">{rendered || "N/A"}</Text>
            </ListItem>
          );
        }
      })}
    </List>
  );
};

export default DocumentSummary;
