import React, { useEffect } from "react";
import "~/app.scss";
import { useNavigate, Routes, Route } from "react-router-dom";
import PageNotFound from "~/pages/PageNotFound";
import authStore from "~/stores/auth";
import { generatePkceAuthUrl } from "~/auth/pkce";
import { observer } from "mobx-react";
import { useNetworkErrorHandle, useQuery } from "~/util/hooks";
import Nav from "~/components/Nav";
import "@wfp/ui/src/globals/scss/styles.scss";
import DocumentsPage from "~/pages/Documents";
import BulkDownloadModal from "~/components/BulkDownloaderModal";
import Footer from "~/components/Footer";
import AdvancedSearchPage from "~/pages/AdvancedSearchPage";
import UploadPage from "~/pages/UploadPage";
import paths from "~/paths";
import DocumentDetailPage, { DocumentDetailPageRedirect } from "~/pages/DocumentDetailPage";
import { Loading } from "@wfp/ui";
import PersonalArea from "~/pages/PersonalArea";
import AppContext, { CallContextProps } from "./AppContext";
import { configure } from "mobx";
import HomePage from "~/pages/HomePage/Home";
import { clarity } from "react-microsoft-clarity";
import * as process from "process";
import auth from "~/stores/auth";

// batch mobx state updates by default
setTimeout(() => {
  configure({
    enforceActions: "never",
    reactionScheduler: (f) => setTimeout(f),
  });
});

const currPath = () => {
  if (window.location.href) {
    return window.location.href.substring(window.location.origin.length);
  }
  return "";
};

const AuthCallbackPage = () => {
  const search = useQuery();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      try {
        await authStore.loginWithCode(search.code);
        // after successful login, navigate to the previously accessed page or the index page
        const lastAccessedPath = localStorage.getItem(LAST_ACCESSED_PATH_BEFORE_AUTH) ?? "/";
        navigate(lastAccessedPath);
      } catch (err) {}
    })();
    return () => {
      localStorage.removeItem(LAST_ACCESSED_PATH_BEFORE_AUTH);
    };
  }, [search.code]);

  return <div>Logging in...</div>;
};

const LAST_ACCESSED_PATH_BEFORE_AUTH = "last-accessed-path-before-auth";

// Navigate to the auth provider's interactive login page
const goToLoginPage = async () => {
  // Save the current URL before going to the auth provider page so that we can restore it when the authentication is completed
  const currentPath = currPath();
  localStorage.setItem(LAST_ACCESSED_PATH_BEFORE_AUTH, currentPath);

  const link = await generatePkceAuthUrl();
  window.location.assign(link);
};

clarity.init(process.env.REACT_APP_CLARITY_ID!);

const LoggedInPage = observer(() => {
  useNetworkErrorHandle();
  useEffect(() => {
    if (!authStore.loggedIn) {
      goToLoginPage();
    } else {
      authStore.fetchUserInfo();
    }
  }, [authStore.loggedIn]);

  useEffect(() => {
    if (clarity.hasStarted() && authStore.userInfo?.user) {
      clarity.identify("USER_ID", {
        userProperty: authStore.userInfo.user,
      });
    }
  }, [authStore.userInfo?.user]);

  if (!authStore.loggedIn) {
    return null;
  }

  return (
    <div className="App">
      <Nav />
      <div style={{ marginTop: 90 }}>
        {/*<NotificationsDisplay />*/}
        {authStore.loading ? (
          <div className="df h-100 w-100 pv-16 fc">
            <Loading className="loader loader_sm" withOverlay={false}>
              Loading the page
            </Loading>
          </div>
        ) : (
          <Routes>
            <Route path={paths.match.index} element={<HomePage />} />
            <Route path="/advanced-search/" element={<AdvancedSearchPage />} />
            <Route path={paths.match.documents} element={<DocumentsPage />} />
            <Route path={paths.match.browse} element={<DocumentsPage />} />
            <Route path="/search/detail/:id" element={<DocumentDetailPageRedirect />} />
            <Route path={paths.match.docDetail} element={<DocumentDetailPage />} />
            <Route path={paths.match.personalArea} element={<PersonalArea />} />
            <Route path="/upload/*" element={<UploadPage />} />
            <Route path={paths.match.editDocument} element={<UploadPage />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        )}
        <BulkDownloadModal />
        <Footer />
      </div>
    </div>
  );
});

const App = observer((props: CallContextProps) => {
  return (
    <AppContext callContext={props.callContext}>
      <Routes>
        <Route path="/callback" element={<AuthCallbackPage />} />
        <Route path="*" element={<LoggedInPage />} />
      </Routes>
    </AppContext>
  );
});

export default App;
