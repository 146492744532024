import { BrowserRouter as Router } from "react-router-dom";
import React from "react";
import ToastNotificationWrapper from "~/components/ToastNotificationContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export type CallContextProps = {
  callContext?: "godocs" | "ebms";
};

const AppConfigContext = React.createContext({ callContext: "ebms" });

const AppContext = ({
  children,
  callContext = "ebms",
}: {
  children: React.ReactNode;
} & CallContextProps) => {
  return (
    <AppConfigContext.Provider value={{ callContext }}>
      <Router>
        <DndProvider backend={HTML5Backend}>
          <ToastNotificationWrapper>{children}</ToastNotificationWrapper>
        </DndProvider>
      </Router>
    </AppConfigContext.Provider>
  );
};

export default AppContext;
