import languageStore, { LangId } from "~/stores/language";
import tagStore, { TagId } from "~/stores/tags";
import { Access, accessToText, DateRange, DocInstance, docInstanceLabel, DocSeriesId, Document } from "~/document";
import topicStore, { TopicId } from "~/stores/topics";
import countryStore, { CountryId } from "~/stores/country";
import docTypeStore, { DocTypeId } from "~/stores/documentTypes";
import docSeriesStore from "~/stores/docSeries";
import projectStore, { ProjectId } from "~/stores/projects";
import ownerStore, { OwnerId } from "~/stores/owners";
import officeStore, { OfficeId } from "~/stores/offices";
import divisionStore, { DivisionId } from "~/stores/divisions";
import { CustomFormatter, SearchConverter, searchConverter } from "~/util/searchConverter";
import moment from "moment";
import { dateToAPIString, formatDateReadable } from "~/util";
import { PUB_DATE_FORMAT } from "./constants";
import { SavedSearchInfo } from "./stores/savedSearch";
import { isSet } from "lodash";
import { MeetingId } from "~/stores/agendaItemDocs";

type OnlyArraySubProperties<T> = {
  [Property in keyof T as Exclude<Property, unknown[]>]: T[Property];
};

type KeyofOnlyArrays<T> = keyof OnlyArraySubProperties<T>;

type addPrefix<TKey, TPrefix extends string> = TKey extends string ? `${TPrefix}${TKey}` : never;

type addSuffix<TKey, TPrefix extends string> = TKey extends string ? `${TKey}${TPrefix}` : never;

type MatchStuff = {
  [K in keyof Document]: Document[K];
} & {
  [K in addSuffix<keyof Partial<Document>, ".like">]: any;
} & {
  [K in addSuffix<keyof Partial<Document>, ".std">]: any;
} & {
  "metadata.ebmeetingdocs.document_instance.value": any;
};

type SearchFilter = {
  match?: Partial<MatchStuff>;
  range?: SearchFilter;
  or?: SearchFilter[];
  and?: SearchFilter[];
};

type APISearchQuery = URLSearchParams;

enum SortOrder {
  Descending = "desc",
  Ascending = "asc",
}

interface SortBy {
  field: keyof Document;
  order: SortOrder;
}

export type SearchQueryJSON = Partial<Record<keyof SearchQuery, { label: string; value: string | number | string[] }>>;

const ADVANCED_SEARCH_FILTERS: Set<keyof SearchQuery> = new Set([
  "languages",
  "tags",
  "topics",
  "docTypes",
  "countries",
  "author",
  "protocol",
  "projects",
  "access",
  "instances",
  "series",
  "publication_date",
  "expiration_date",
  "release_date",
  "owners",
  "offices",
  "divisions",
  "searchAnywhere",
  "is_title_visible_to_public",
]);

export const searchQueryCustomKeys = ["is_title_visible_to_public"];

export type SearchQueryCustom = {
  is_title_visible_to_public?: 0 | 1;
};

// Represents the document search query. Can be converted to JSON, readable JSON, API call, etc.
//
// Uses the Builder pattern. In order to modify the query, use `query.set(key, value)`. This will
// return a new instance of the query with one value modified. `removeSimpleFilter` and such can be used
// to remove values.
class SearchQuery {
  title = "";
  author = "";
  protocol = "";
  number: string = "";
  abstract: string = "";
  comment?: string = "";
  pdf_option?: number = 1;
  languages: LangId[] = [];
  topics: Set<TopicId> = new Set([]);
  tags: TagId[] = [];
  countries: CountryId[] = [];
  projects: ProjectId[] = [];
  access: Access[] = [];
  docTypes: Set<DocTypeId> = new Set([]);
  meeting_codes: MeetingId[] = [];
  instances: DocInstance[] = [];
  series: DocSeriesId[] = [];
  expiration_date: DateRange | undefined = undefined;
  publication_date: DateRange | undefined = undefined;
  release_date: DateRange | undefined = undefined;
  owners: OwnerId[] = [];
  offices: OfficeId[] = [];
  divisions: DivisionId[] = [];
  searchAnywhere: string = "";
  note?: string;
  is_title_visible_to_public?: boolean;
  custom: SearchQueryCustom = {};

  page: number = 0;
  pageSize: number = 10;

  sortByField: string = "-publication_date";
  sortByOrder: SortOrder = SortOrder.Descending;

  constructor(qq?: Partial<SearchQuery>) {
    if (qq) {
      for (let [propName, propValue] of Object.entries(qq)) {
        (this as any)[propName] = propValue;
      }
    }
  }

  static fromQueryString(queryString: URLSearchParams) {
    return searchQueryQueryConverter.from(queryString);
  }

  isValid(): boolean {
    return !this.isEmpty();
  }

  isTitlePresent(): boolean {
    return this.title.trim().length !== 0;
  }

  isOneOfTheFieldsSelected(): boolean {
    return this.isTitlePresent() || this.isThereAnyAdvancedFiltersApplied();
  }

  toJSON() {
    return { ...this, topics: Array.from(this.topics), docTypes: Array.from(this.docTypes) };
  }

  toFullJSON(): SearchQueryJSON {
    return {
      title: {
        label: "Title",
        value: this.title,
      },
      languages: {
        label: "Languages",
        value: this.languages.map((langId) => {
          if (!(langId in languageStore.loadedLanguages)) return "";
          return languageStore.loadedLanguages[langId].text;
        }),
      },
      is_title_visible_to_public: {
        label: "Title visible to public",
        value:
          this.custom.is_title_visible_to_public === 1
            ? "Title visible to public"
            : this.custom.is_title_visible_to_public === 0
            ? "Title not visible to public"
            : null,
      },
      tags: { label: "Tags", value: this.tags.map((tagId) => tagStore.f.items[tagId]?.label) },
      author: { label: "Author", value: this.author },
      topics: {
        label: "Topics",
        value: Array.from(this.topics)
          .map((id) => {
            return topicStore.topicsById.get(id as number)?.label || null;
          })
          .filter((a) => a !== null) as string[],
      },
      docTypes: {
        label: "Document types",
        value: Array.from(this.docTypes)
          .map((id) => {
            return docTypeStore.docTypesById.get(id)?.label || null;
          })
          .filter((a) => a !== null) as string[],
      },
      countries: {
        label: "Geographical coverage",
        value: Array.from(this.countries).map((id) => {
          const sid = String(id);
          return countryStore.f.items[sid]?.name;
        }),
      },
      owners: {
        label: "Owners",
        value: Array.from(this.owners).map((id) => {
          const sid = String(id);
          return ownerStore.f.items[sid]?.username;
        }),
      },
      protocol: { label: "Document symbol", value: this.protocol },
      projects: { label: "Project number", value: this.projects },
      meeting_codes: { label: "Meeting codes", value: this.meeting_codes },
      access: { label: "Access level", value: this.access.map(accessToText) },
      searchAnywhere: { label: "Search Anywhere", value: this.searchAnywhere },
      instances: {
        label: "Instances",
        value: this.instances.map((id) => {
          return docInstanceLabel[id];
        }),
      },
      series: {
        label: "Series",
        value: this.series.map((id) => {
          return docSeriesStore.loaded[id].label;
        }),
      },
      publication_date: {
        label: "Publication date",
        value: this.publication_date
          ? `${moment(this.publication_date[0]).format("DD-MM-YYYY")} -> ${moment(this.publication_date[1]).format(
              "DD-MM-YYYY"
            )}`
          : "",
      },
      offices: {
        label: "Offices",
        value: Array.from(this.offices).map((id) => {
          const sid = String(id);
          return officeStore.f.items[sid]?.name;
        }),
      },
      divisions: {
        label: "Divisions",
        value: Array.from(this.divisions).map((id) => {
          const sid = String(id);
          return divisionStore.f.items[sid]?.name;
        }),
      },
    };
  }

  // Convert to a API format to be used in the /search-restful/ endpoint
  toAPIFormat(): APISearchQuery {
    const qry = new URLSearchParams();

    const addRangeParamIfPresent = (paramName: keyof typeof this) => {
      const val: any[] = this[paramName] as any;
      if (!val || val.length === 0) return;
      const gte = dateToAPIString(val[0]);
      if (val.length >= 2) {
        const lte = dateToAPIString(val[1]);
        qry.set(`${String(paramName)}__range`, [gte, lte].join("__"));
      } else {
        qry.set(`${String(paramName)}__gte`, gte);
      }
    };

    const addOrParam = (name: string, arr: any[]) => {
      if (arr.length !== 0) {
        qry.set(`${name}__in_all`, arr.join("__"));
      }
    };

    addOrParam("language", this.languages);
    addOrParam("office", this.offices);
    addOrParam("owner", this.owners);
    addOrParam("acess", this.access);
    qry.set("author__contains", this.author);

    addOrParam("instance", this.instances);

    addRangeParamIfPresent("publication_date");
    addRangeParamIfPresent("expiration_date");
    addRangeParamIfPresent("release_date");

    if (this.searchAnywhere && this.searchAnywhere.trim().length !== 0) {
      qry.set("search", this.searchAnywhere);
    }

    qry.set("title__wildcard", this.title);

    if (this.topics && this.topics.size !== 0) {
      addOrParam("topics", Array.from(this.topics));
    }
    if (this.meeting_codes.length) {
      addOrParam("codes", this.meeting_codes);
    }
    if (this.docTypes && this.docTypes.size !== 0) {
      addOrParam("types", Array.from(this.docTypes));
    }
    if (this.countries && this.countries.length !== 0) {
      addOrParam("countries", Array.from(this.countries));
    }

    // Sorting params
    qry.set("ordering", this.sortByField);

    return qry;
  }

  toQueryString() {
    return searchQueryQueryConverter.to({ ...this, ...this.custom });
  }

  set<K extends keyof this>(key: K, value: (typeof this)[K]) {
    this[key] = value;
    return new SearchQuery(this);
  }

  setCustom(key: keyof SearchQueryCustom, value?: any) {
    this.custom[key] = value;
    return new SearchQuery(this);
  }

  remove(
    fieldName: keyof this,
    params: {
      key?: string;
      idx?: number;
    }
  ) {
    if (searchQueryCustomKeys.includes(fieldName.toString())) {
      delete this.custom[fieldName as keyof SearchQueryCustom];
      return new SearchQuery(this);
    }
    if (this[fieldName] instanceof Array) {
      return this.removeArrayFilterPart(fieldName as KeyofOnlyArrays<this>, params.idx);
    }
    if (isSet(this[fieldName])) {
      let itemToRemove = params.key;
      if (!itemToRemove && !isNaN(+params.idx)) {
        itemToRemove = Array.from(this[fieldName] as Set<any>)[params.idx];
      }
      return this.removeSetFilterPart(fieldName, itemToRemove);
    }
    return this.removeSimpleFilter(fieldName);
  }

  removeArrayFilterPart(key: KeyofOnlyArrays<this>, idx: number) {
    (this[key] as any).splice(idx, 1);
    return new SearchQuery(this);
  }

  removeSetFilterPart(fieldName: keyof this, key: string) {
    (this[fieldName] as any as Set<any>).delete(key);
    return new SearchQuery(this);
  }

  removeSimpleFilter(key: keyof this) {
    delete this[key];
    return new SearchQuery(this);
  }

  *forAllAppliedAdvancedSearchFilters() {
    for (let field of ADVANCED_SEARCH_FILTERS) {
      const val = this[field];
      if (val instanceof Array) {
        if (val.length !== 0) yield val;
      } else if (val instanceof Set) {
        if (val.size !== 0) yield val;
      } else if (val) {
        yield val;
      }
    }
  }

  isEmpty() {
    return !this.isTitlePresent() && !this.isThereAnyAdvancedFiltersApplied();
  }

  isThereAnyAdvancedFiltersApplied() {
    for (let val of this.forAllAppliedAdvancedSearchFilters()) {
      return true;
    }
    return false;
  }

  numOfAppliedSearchFilters() {
    let k = 0;
    for (let val of this.forAllAppliedAdvancedSearchFilters()) {
      ++k;
    }
    return k;
  }

  isAdvancedFilterField(field: keyof SearchQuery) {
    return ADVANCED_SEARCH_FILTERS.has(field);
  }

  // Will make API requests for all missing data found in this query
  requestAllMissingDataForQuery = async () => {
    const promises: Promise<any>[] = [];
    if (this.languages.length !== 0) {
      promises.push(languageStore.loadLanguages());
    }
    if (this.countries.length !== 0) {
      promises.push(countryStore.f.loadIds(this.countries));
    }
    if (this.tags.length !== 0) {
      promises.push(tagStore.f.loadIds(this.tags));
    }
    if (this.docTypes.size !== 0) {
      promises.push(docTypeStore.loadDocTypes());
    }
    if (this.topics.size !== 0) {
      promises.push(topicStore.loadTopics());
    }
    if (this.projects.length !== 0) {
      promises.push(projectStore.f.loadIds(this.projects));
    }
    if (this.offices.length !== 0) {
      promises.push(officeStore.f.loadIds(this.offices));
    }
    if (this.series.length != 0) {
      promises.push(docSeriesStore.f.loadIds(this.series));
    }
    if (this.owners.length !== 0) {
      promises.push(ownerStore.f.loadIds(this.owners));
    }
    if (this.divisions.length !== 0) {
      promises.push(divisionStore.f.loadIds(this.divisions));
    }
    return await Promise.all(promises);
  };

  static fromSavedSearch = (savedSearch: SavedSearchInfo): SearchQuery => {
    let sq = new SearchQuery(savedSearch.complex_search_value);
    sq = sq.set("title", savedSearch.search_value);
    return sq;
  };
}

// This is a custom converter for dates
const dateRangeConverter: CustomFormatter<DateRange> = {
  to: (val) => {
    if (val.length <= 0) return null;
    const _from = val[0] ? formatDateReadable(val[0]) : null;
    const _to = val[1] ? formatDateReadable(val[1]) : null;
    return [_from, _to].filter((a) => a !== null).join(",");
  },
  from: (val) => {
    const r = val.split(",");
    let res: any = r.map((item) => moment(item, PUB_DATE_FORMAT).toDate());
    return res;
  },
};

// This is used to convert back and forth between search query URL representation and SearchQuery instances
// to communicate between app pages
const searchQueryQueryConverter = searchConverter<SearchQuery>(
  {
    title: SearchConverter.String,
    searchAnywhere: SearchConverter.String,
    author: SearchConverter.String,
    protocol: SearchConverter.String,
    is_title_visible_to_public: SearchConverter.NumberToBoolean,

    languages: SearchConverter.Array,
    tags: SearchConverter.Array,
    topics: SearchConverter.NumberSet,
    countries: SearchConverter.Array,
    projects: SearchConverter.Array,
    access: SearchConverter.Array,
    docTypes: SearchConverter.NumberSet,
    instances: SearchConverter.Array,
    series: SearchConverter.Array,
    meeting_codes: SearchConverter.Array,

    expiration_date: dateRangeConverter,
    publication_date: dateRangeConverter,
    release_date: dateRangeConverter,

    owners: SearchConverter.Array,
    offices: SearchConverter.Array,
    divisions: SearchConverter.Array,

    sortByField: SearchConverter.String,
    sortByOrder: SearchConverter.String,

    page: SearchConverter.Number,
    pageSize: SearchConverter.Number,
  },
  () => new SearchQuery()
);

export default SearchQuery;
