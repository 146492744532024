import { DocPayload, EditableDocPayload } from "~/document";
import { observer } from "mobx-react";
import paths from "~/paths";
import { useState, useEffect, useMemo } from "react";
import { FormGroup, Modal, Button } from "@wfp/ui";
import { Column, usePagination, useTable } from "react-table";
import { Link } from "react-router-dom";
import templatesStore, { TemplateInfo } from "~/stores/templates";
import DocumentForm, { SingleFormValue, SingleOnChangeKeys } from "~/components/DocumentForm";
import { docPayloadFromFormChange, formValueFromState } from "../UploadPage";
import BouncedTextInput from "~/components/BouncedInput";
import notificationStore from "~/stores/notifications";
import { assert, formatDateReadable } from "~/util";
import languageStore from "~/stores/language";
import TabTable, { CommonTabProps } from "./TabTable";
import TabContent from "./TabContent";
import { useDataFetcher } from "./common";

const AddTemplateModal = (props: {
  close: () => void;
  open: boolean;
  onAdd: (name: string, payload: DocPayload) => Promise<void>;
  initialPayload: TemplateInfo | null;
}) => {
  const [templateName, setTemplateName] = useState<string>("");
  const [docPayload, setDocPayload] = useState<EditableDocPayload>({});
  // load initial template state if editing
  useEffect(() => {
    if (props.initialPayload === null) return;
    setTemplateName(props.initialPayload.description);
    setDocPayload(props.initialPayload.json_template);
  }, [props.initialPayload]);
  const formValue: SingleFormValue = useMemo(() => formValueFromState(docPayload), [docPayload]);
  const onDocChange = (key: SingleOnChangeKeys, value: any) => {
    const newDocPayload = docPayloadFromFormChange(docPayload, key, value);
    setDocPayload(newDocPayload);
  };
  const formProps = {};
  const nameValid = templateName.trim().length !== 0;
  const isTemplateEmpty = Object.keys(docPayload).length === 0;
  const canSubmit = nameValid && !isTemplateEmpty;
  return (
    <Modal
      open={props.open}
      modalHeading="Create a new template"
      modalLabel="Templates"
      onRequestClose={props.close}
      onRequestSubmit={async () => {
        await props.onAdd(templateName, docPayload);
        setDocPayload({});
        setTemplateName("");
        props.close();
      }}
      primaryButtonDisabled={!canSubmit}
      primaryButtonText="Confirm"
      secondaryButtonText="Cancel"
      wide
    >
      <div className="pb-16">
        <FormGroup legendText="Template name">
          <BouncedTextInput
            placeholder="e.g. Country reports fields"
            value={templateName}
            onChange={(value: any) => setTemplateName(value)}
            invalid={!nameValid}
            invalidText={!nameValid && "Template name is required"}
          />
        </FormGroup>
      </div>
      <DocumentForm
        value={formValue}
        onChange={onDocChange}
        multipleDocs={false}
        formProps={formProps}
        docTypeSelectProps={{
          maxOptionsToSelect: 2,
        }}
      />
    </Modal>
  );
};

const TemplatesTab = observer((props: CommonTabProps) => {
  const fetcher = useDataFetcher(props.selected, templatesStore.f.loadBare);
  const items = fetcher.items.results;
  const data = useMemo(() => items, [items]);
  const columns: ReadonlyArray<Column<TemplateInfo>> = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "description",
      },
      {
        Header: "Symbol",
        Cell: (props: any) => {
          const doc = props.row.original;
          return <div className="space-x-6">{doc.json_template.protocol ?? "N/A"}</div>;
        },
      },
      {
        Header: "Language",
        accessor: "json_template",
        Cell: (props) => {
          const doc = props.row.original;
          return (
            <div className="space-x-6">
              {doc.json_template.language ? languageStore.idToText(doc.json_template.language) : "N/A"}
            </div>
          );
        },
      },
      {
        Header: "Release date",
        Cell: (props: any) => {
          const doc = props.row.original;
          return (
            <div className="space-x-6">
              {doc.json_template.release_date ? formatDateReadable(doc.json_template.release_date) : "N/A"}
            </div>
          );
        },
      },
      {
        Header: "Expires at",
        Cell: (props: any) => {
          const doc = props.row.original;
          return (
            <div className="space-x-6">
              {doc.json_template.expiration_date ? formatDateReadable(doc.json_template.expiration_date) : "N/A"}
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: (props) => {
          const doc = props.row.original;
          return (
            <div className="space-x-6">
              <Link to={paths.construct.uploadDocWithTemplate(doc.id)}>Upload</Link>
              <Link
                to="#"
                onClick={async (e) => {
                  e.preventDefault();
                  const template = await templatesStore.getTemplate(doc.id);
                  setInitialTemplatePayload(template);
                  setShowTemplateModal(true);
                }}
              >
                Edit
              </Link>
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  setTemplateToDelete(doc);
                }}
              >
                Delete
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );
  const tableInstance = useTable({ columns: columns as any, data }, usePagination);
  const [showTemplateModal, setShowTemplateModal] = useState(false);

  // stores edited payload
  const [initialTemplatePayload, setInitialTemplatePayload] = useState<TemplateInfo | null>(null);
  const editing = initialTemplatePayload !== null;

  const onAdd = async (templateName: string, docPayload: DocPayload) => {
    if (editing) {
      await templatesStore.editTemplate(initialTemplatePayload.id, templateName, docPayload);
      notificationStore.success("Edited document successfully!");
    } else {
      // new template
      await templatesStore.addTemplate(templateName, docPayload);
      await fetcher.reload();
      notificationStore.success("Created new template.");
    }
  };
  const [templateToDelete, setTemplateToDelete] = useState<TemplateInfo | null>(null);
  const templateDeletionModalShown = templateToDelete !== null;

  return (
    <TabContent>
      <Modal
        open={templateDeletionModalShown}
        modalHeading="Delete template"
        modalLabel="Templates"
        onRequestClose={() => setTemplateToDelete(null)}
        primaryButtonText="Confirm"
        secondaryButtonText="Cancel"
        onRequestSubmit={async () => {
          assert(templateToDelete !== null, "Template should already be selected when deletion modal is open");
          await templatesStore.f.delete(templateToDelete.id);
          notificationStore.success("Deleted template");
          await fetcher.reload();
          setTemplateToDelete(null);
        }}
      >
        Do you really want to delete the template "{templateToDelete?.description}"?
      </Modal>
      <AddTemplateModal
        open={showTemplateModal}
        close={() => setShowTemplateModal(false)}
        onAdd={onAdd}
        initialPayload={initialTemplatePayload}
      />
      <div className="df mb-16">
        <div className="fspace" />
        <Button kind="primary" onClick={() => setShowTemplateModal(true)}>
          Add template
        </Button>
      </div>
      <TabTable
        fetcher={fetcher}
        tableInstance={tableInstance}
        emptyTitle="No templates"
        emptyMessage="You haven't created any templates"
      />
    </TabContent>
  );
});

export default TemplatesTab;
