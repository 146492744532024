import { Modal } from "@wfp/ui";
import { DocPayload } from "~/document";
import DocumentSummary from "~/components/DocumentSummary";
import styles from "./styles.module.scss";

interface ConfirmationModalProps {
  docPayload: DocPayload;
  onAgree: () => void;
  close: () => void;
  open: boolean;
  submitting: boolean;
  editing: boolean;
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
  return (
    <Modal
      open={props.open}
      modalHeading={props.editing ? "Confirm edit" : "Confirm upload"}
      modalLabel={props.editing ? "Edit document" : "Upload"}
      onRequestClose={props.close}
      onRequestSubmit={props.onAgree}
      primaryButtonText={props.submitting ? "Submitting..." : "Confirm"}
      primaryButtonDisabled={props.submitting}
    >
      <div className={styles.confirmationModal}>
        <DocumentSummary doc={props.docPayload} />
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
