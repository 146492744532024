import { useCallback } from "react";
import { Link, Icon, Text } from "@wfp/ui";
import styles from "./styles.module.scss";
import { useDropzone } from "react-dropzone";
import { iconCheckmark } from "@wfp/icons";
import cn from "classnames";
import { iconDownloadGlyph } from "@wfp/icons";

interface FileDropAreaProps {
  onAttachFile: (files: File[]) => void;
  value: File[];
  className?: string;
}

const FileDropArea = (props: FileDropAreaProps) => {
  const { onAttachFile } = props;
  const onDrop = useCallback((acceptedFiles) => {
    onAttachFile(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const isThereAnythingSelected = props.value.length !== 0;
  return (
    <div
      title="Select document to upload"
      className={cn(
        { [styles.uploadArea]: true, [styles.uploadArea_selectedFiles]: isThereAnythingSelected },
        props.className
      )}
    >
      <div className={styles.uploadAreaInner} {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <>
            {!isThereAnythingSelected ? (
              <>
                <Icon icon={iconDownloadGlyph} fill="#007DBC" width={"60px"} height={"60px"} className="mb-16" />
                <strong className="mb-8 fs-14">
                  Drop files to upload or{" "}
                  <Link
                    href="#"
                    onClick={(e: any) => {
                      e.preventDefault();
                    }}
                  >
                    browse
                  </Link>
                </strong>
                <Text className="text-02 fs-12">pdf, xls, doc, maximum up to 500MB</Text>
              </>
            ) : (
              <>
                <Icon icon={iconCheckmark} fill="#007DBC" width={"60px"} height={"60px"} className="mb-16" />
                <strong className="mb-8 fs-14">Selected {props.value[0].name}</strong>
                <Text className="text-02 fs-12">Click to select another document</Text>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FileDropArea;
