import React, { useState } from "react";
import styles from "./styles.module.scss";
import { ToastNotification, ToastNotificationProps } from "@wfp/ui";

type Props = {
  children: React.ReactNode;
};

export const ToastNotificationContext = React.createContext<{
  notificationData: ToastNotificationProps;
  notificationVisible: boolean;
  setNotificationData: (value: ToastNotificationProps) => void;
  setNotificationVisible: (value: boolean) => void;
  showNotificationWithTimeout: () => void;
}>({
  notificationData: { kind: "success" },
  notificationVisible: false,
  setNotificationData: () => {},
  setNotificationVisible: () => {},
  showNotificationWithTimeout: () => {},
});

const ToastNotificationWrapper = ({ children }: Props) => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<ToastNotificationProps>({
    kind: "success",
    className: styles.toast_notification,
  });

  const showWithTimeout = () => {
    setVisible(true);
    setTimeout(() => setVisible(false), 5000);
  };

  return (
    <ToastNotificationContext.Provider
      value={{
        notificationData: data,
        notificationVisible: visible,
        setNotificationData: setData,
        setNotificationVisible: setVisible,
        showNotificationWithTimeout: showWithTimeout,
      }}
    >
      {visible && <ToastNotification {...data} className={styles.toast_notification} caption={data.caption || ""} />}
      {children}
    </ToastNotificationContext.Provider>
  );
};

export default ToastNotificationWrapper;
