import { useEffect, createContext, useContext, useLayoutEffect, useMemo, useState, useRef, useCallback } from "react";
import PaginatedEndpoint from "~/util/PaginatedEndpoint";
import { SelectOption, DataWithId } from "~/types";
import { ActionMeta, OnChangeValue, Props as SelectProps } from "react-select";
import { observer } from "mobx-react";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import styles from "./styles.module.scss";

interface Context {
  loadMore: () => Promise<any>;
}
const Ctx = createContext<Context>({} as any);

const MenuList = (props: any) => {
  const { loadMore } = useContext(Ctx);
  const ref = useRef<any>(null);
  const childRef = useRef<HTMLDivElement>(null);
  const onScroll = (entries: any[], observer: IntersectionObserver) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        loadMore();
      }
    });
  };
  useLayoutEffect(() => {
    if (ref.current === null) return;
    const container = ref.current;
    let options = {
      root: container,
      rootMargin: "10px",
      threshold: 0.5,
    };
    let observer = new IntersectionObserver(onScroll, options);
    if (childRef.current === null) return;
    observer.observe(childRef.current);
  }, []);
  return (
    <>
      <components.MenuList
        {...props}
        className={styles.menu}
        innerRef={(cref) => {
          ref.current = cref;
        }}
      >
        {props.children}
        <div ref={childRef} />
      </components.MenuList>
    </>
  );
};

export interface AsyncPaginatedSelectProps<T extends DataWithId, K = T["id"]> {
  fetcher: PaginatedEndpoint<T>;
  itemIdToOption: (id: K) => SelectOption | null;
  value: K[];
  onChange: (newValue: K[]) => void;
  selectProps?: SelectProps;
  filter?: (item: T) => boolean;
  isCreatable?: boolean;
  onCreate?: (label: string) => Promise<T>;
  idKey?: string;
  additionalSearchOptions?: { [key: string]: string };
}

const AsyncPaginatedSelect = observer(function <T extends DataWithId>(props: AsyncPaginatedSelectProps<T>) {
  const { itemIdToOption, fetcher, idKey, additionalSearchOptions } = props;
  const isCreatable = props.isCreatable ?? false;
  const isLoading = fetcher.loading;
  const filter = props.filter ?? ((item) => true);
  const getAllOptions = () =>
    fetcher.itemsList
      .filter(filter)
      .map((item) => itemIdToOption(item[idKey || "id"]))
      .filter((a) => a);
  const options = useMemo(getAllOptions, [fetcher.itemsList]);
  const [inputValue, setInputValue] = useState<string>("");

  const loadMoreDebounced = useCallback(async () => {
    let cursor = fetcher.cursor;
    if (inputValue !== fetcher.lastSearchedQuery?.toString()) {
      cursor = null;
    }
    if (fetcher.hasMore || inputValue !== fetcher.lastSearchedQuery.toString()) {
      await fetcher.load(inputValue, cursor, additionalSearchOptions);
    }
    const options = getAllOptions();
    return {
      options,
      hasMore: fetcher.hasMore,
    };
  }, [fetcher.itemsList, fetcher.cursor, idKey, inputValue, additionalSearchOptions]);

  const loadMoreWithCurrentSearchValue = async () => loadMoreDebounced();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (inputValue.trim() !== "") {
      timer = setTimeout(loadMoreWithCurrentSearchValue, 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [inputValue]);

  const selectedOptions = props.value.map(props.itemIdToOption).filter((a) => a);

  const onCreate = props.onCreate ?? (() => {});

  const onChange: any = async (options: OnChangeValue<any, true>, actionMeta: ActionMeta<any>) => {
    if (options instanceof Array) {
      props.onChange(options.map((a: any) => a.value));
    } else if (options) {
      props.onChange((options as any).value);
    } else {
      props.onChange([]);
    }
  };

  const handleCreate = async (inputValue: string) => {
    const resp = await onCreate(inputValue);
    if (resp === null) {
      return;
    }
    const newTag = resp as T;
    const newOptions = [...props.value, newTag.id];
    props.onChange(newOptions);
  };

  const sProps: any = {
    placeholder: "Nothing selected",
    isClearable: true,
    isSearchable: true,
    components: { MenuList },
    value: selectedOptions,
    inputValue: inputValue,
    onInputChange: (newValue: any, action: any) => {
      if (action.action === "input-change" || action.action === "set-value") setInputValue(newValue);
    },
    options: options,
    isLoading: isLoading,
    onChange: onChange,
    ...props.selectProps,
  };

  return (
    <Ctx.Provider value={{ loadMore: loadMoreWithCurrentSearchValue }}>
      {!isCreatable ? (
        <Select {...sProps} escapeClearsValue={false} />
      ) : (
        <CreatableSelect {...sProps} onCreateOption={handleCreate} />
      )}
    </Ctx.Provider>
  );
});

export default AsyncPaginatedSelect;
