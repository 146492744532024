import React, { useContext, useEffect } from "react";
import docStore from "~/stores/document";
import { Loading, Text } from "@wfp/ui";
import { observer } from "mobx-react";
import styles from "./styles.module.scss";
import DocItem from "~/components/DocItem";
import DocumentsContext from "./DocumentsContext";
import { useDataFetcher } from "~/pages/PersonalArea/common";
import { docItemToDocument, Document } from "~/document";

const LatestUploadedList = observer(() => {
  const { onDocumentClick } = useContext(DocumentsContext);
  const fetcher = useDataFetcher(true, docStore.loadLatestDocuments, {
    defaultSortBy: "-creation_date",
  });
  if (fetcher.loading) {
    return (
      <div className="df h-100 w-100 fc f-100 fdc">
        <Loading className="loader loader_sm" withOverlay={false}>
          Text
        </Loading>
      </div>
    );
  }

  return (
    <div className={styles.leftColContent}>
      <Text kind="h4" className="mb-24">
        Latest uploads
      </Text>
      {!!fetcher.items?.results &&
        fetcher.items.results.map((item) => {
          return <DocItem key={item.id} doc={item} onDocumentClick={() => onDocumentClick(item)} />;
        })}
    </div>
  );
});

export default LatestUploadedList;
