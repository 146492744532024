import docStore from "~/stores/document";
import { Text, Button } from "@wfp/ui";
import { iconFavoriteOutline, iconFavoriteSolid } from "@wfp/icons";
import { Document } from "~/document";
import cn from "classnames";
import { observer } from "mobx-react";
import IconButton from "../IconButton";
import styles from "./styles.module.scss";
import React, { useEffect, useState } from "react";
import bookmarkStore from "~/stores/bookmarks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

const DocBookmarkButton = observer((props: { doc: Document; className?: string }) => {
  const { doc } = props;
  const storeState = bookmarkStore.isBookmarked(doc.id);
  const [bookmarked, setBookmarked] = useState(storeState);

  useEffect(() => {
    setBookmarked(storeState);
  }, [storeState]);

  const title = bookmarked ? "Remove bookmark" : "Add bookmark";

  const toggle = async () => {
    const newState = !bookmarked;
    await bookmarkStore.toggleBookmarkedStatus(doc, newState);
    setBookmarked(newState);
  };

  const icon = bookmarked ? solid("star") : regular("star");

  useEffect(() => {
    // bookmarkStore.loadFor(props.doc);
  }, []);

  return (
    <Button
      small
      kind={bookmarked ? "primary" : "secondary"}
      area-label={title}
      title={title}
      onClick={toggle}
      className={cn(
        styles.button,
        {
          [styles.active]: bookmarked,
        },
        props.className
      )}
    >
      <span>{bookmarked ? "Bookmarked" : "Add bookmark"}</span>
      <FontAwesomeIcon icon={icon} />
    </Button>
  );
});

export default DocBookmarkButton;
