import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

type Props = {
  onChange: (htmlString: string) => void;
  defaultValue?: string;
};

const TextAreaWithLink = ({ onChange, defaultValue }: Props) => {
  const [markup, setMarkup] = useState("");

  useEffect(() => {
    onChange(markup);
  }, [markup]);

  useEffect(() => {
    setMarkup(defaultValue || "");
  }, []);

  return (
    <div className={styles.container}>
      <ReactQuill
        style={{ width: "100%", fontFamily: '"Open Sans", sans-serif' }}
        modules={{ toolbar: ["link", "bold", "italic"] }}
        formats={["link", "bold", "italic"]}
        value={markup}
        onChange={setMarkup}
      />
    </div>
  );
};

export default TextAreaWithLink;
